const gnomeData = {
  parts: [
    {
      name: "Barbe",
      nameEnglish: "beard",
      baseColor: '#78160c',
    },
    {
      name: "Ceinture",
      nameEnglish: "belt",
      baseColor: '#E7D63E',
    },
    {
      name: "Chaussure",
      nameEnglish: "boot",
      baseColor: '#D90BE7',
    },
    {
      name: "Corps",
      nameEnglish: "corpse",
      baseColor: '#5DE73A',
    },
    {
      name: "Chapeau",
      nameEnglish: "hat",
      baseColor: '#E72E44',
    },
    {
      name: "Pantalon",
      nameEnglish: "pants",
      baseColor: '#282DE7',
    },
    {
      name: "Peau",
      nameEnglish: "skin",
      baseColor: '#E6E796',
    },
  ]
}

export default gnomeData 
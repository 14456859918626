import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

// import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

/**
 * A class used to load various WebGL assets.
 *
 * Can load :
 * - _GLTF_/_GLB_ files (_Draco_ is supported).
 * - Texture files.
 *
 * @class
 */
export default class Loader {
  /**
   * Loads one or many .gtlf/.glb model(s) using `THREE.GLTFLoader`.
   *
   * Draco-compressed models are supported.
   *
   * @param {(String|Array<String>)} paths - The string path to the asset. Can be an array of paths.
   * @param {Boolean} isDraco - If true a `DRACOLoader` will be used.
   *
   * @static
   * @async
   * @returns {Promise} - A Promise resolved when all assets are loaded.
   */
  static async loadGLTF(paths, isDraco) {
    if (!paths) return Promise.resolve()

    // If the Loader has never been created, initialize it.
    if (!Loader.gltfLoader) {
      const gltfLoader = new GLTFLoader()

      Loader.gltfLoader = gltfLoader
    }
    

    if (isDraco && !Loader.dracoLoader) {
      const draco = await new DRACOLoader()
      draco.setDecoderConfig({ type: 'js' });

      if(import.meta.env.MODE === 'dev') {
        draco.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
        draco.preload();
        Loader.gltfLoader.setDRACOLoader(draco)
      } else {
        draco.setDecoderPath('loader/draco/');
        draco.preload();
        Loader.gltfLoader.setDRACOLoader(draco)
      }

      Loader.dracoLoader = draco
    }


    return Loader.load(paths, Loader.gltfLoader)
  }

  /**
   * Loads one or many .gtlf/.glb model(s) using `THREE.GLTFLoader`.
   *
   * Draco-compressed models are supported.
   *
   * @param {(String|Array<String>)} paths - The string path to the asset. Can be an array of paths.
   * @param {Boolean} isDraco - If true a `DRACOLoader` will be used.
   *
   * @static
   * @async
   * @returns {Promise} - A Promise resolved when all assets are loaded.
   */
  static async loadKTX2(paths, renderer) {
    if (!paths) return Promise.resolve()

    // If the Loader has never been created, initialize it.
    if (!Loader.ktx2Loader) {
      const { KTX2Loader } = await import('three/addons/loaders/KTX2Loader.js');
      const ktx2Loader = new KTX2Loader()

      Loader.ktx2Loader = ktx2Loader

      Loader.ktx2Loader.setTranscoderPath('../../loader/basis/');
      Loader.ktx2Loader.detectSupport(renderer)
    }


    return Loader.load(paths, Loader.ktx2Loader)
  }

  /**
   * Loads one or many texture image file(s) using `THREE.TextureLoader`.
   *
   * @param {(String|Array<String>)} paths - The string path to the asset. Can be an array of paths.
   *
   * @static
   * @async
   * @returns {Promise} - A Promise resolved when all assets are loaded.
   */
  static async loadTexture(paths) {
    if (!paths) return Promise.resolve()

    // If the Loader has never been created, initialize it.
    if (!Loader.textureLoader) {
      const { TextureLoader } = await import('three')
      const textureLoader = new TextureLoader()

      Loader.textureLoader = textureLoader
    }

    return Loader.load(paths, Loader.textureLoader)
  }

  /**
   * Loads one or many hdr image file(s) using `THREE.RGBELoader`.
   *
   * @param {(String|Array<String>)} paths - The string path to the asset. Can be an array of paths.
   *
   * @static
   * @async
   * @returns {Promise} - A Promise resolved when all assets are loaded.
   */
  static async loadHDR(paths) {
    if (!paths) return Promise.resolve()

    // If the Loader has never been created, initialize it.
    if (!Loader.hdrLoader) {
      const { RGBELoader } = await import('three/addons/loaders/RGBELoader')
      const hdrLoader = new RGBELoader()

      Loader.hdrLoader = hdrLoader
    }

    return Loader.load(paths, Loader.hdrLoader)
  }

  /**
   * Loads some assets using the specified loader.
   *
   * @param {(String|Array<String>)} paths - The string path to the asset. Can be an array of paths.
   * @param {Object} loader - A instance of a loader.
   *
   * @static
   * @async
   * @returns {Promise} - A Promise resolved when all assets are loaded.
   */
  static async load(paths, loader, type) {
    if (!paths || !loader) return Promise.resolve()

    const promises = []
    const toLoad = Array.isArray(paths) ? paths : [paths]

    for (const path of toLoad) {
      if (path) {
        const promise = new Promise((resolve, reject) => {
          loader.load(path, resolve, undefined, reject)
        })

        promises.push(promise)
      }
    }

    return Promise.all(promises)
  }
}

import gnomeData from '../utils/data.js';
import { spring } from '../utils/spring.js';
import Lenis from 'lenis'
import {randomIntFromInterval} from '../utils/utilsFunc.js';

export default class Home {
  constructor({ parent, size }){

    this.parent = parent;
    this.size = {
      x: size.x,
      y: size.y
    }

    this.gnomeParts = []

    this.imageUrl = null;
    this.imageBlob  = null;

    this.skinsColor = ['#f2efee', '#efe6dd', '#ffe39f','#ffad60','#ffdac6',
      '#be9167','#a88165','#8c624c', '#673e2f','#583123','#9c7248', '#70361c',
      '#65371e','#492816','#321b0f']

    this.propsOpened = false
    this.loaded = false
    this.inputColors = []

    this.bindMethods()

    this.getElems()

    this.events()
  }

  bindMethods() {
    this.update = this.update.bind(this)
    this.resize = this.resize.bind(this)
    this.openCustomize = this.openCustomize.bind(this)
    this.updateColor = this.updateColor.bind(this)
    this.setColor = this.setColor.bind(this)
    this.resetColor = this.resetColor.bind(this)
    this.randomizeColor = this.randomizeColor.bind(this)
    this.hasScreenshot = this.hasScreenshot.bind(this)
    this.askScreenshot = this.askScreenshot.bind(this)
    this.showTooltip = this.showTooltip.bind(this)
  }

  getElems() {
    this.visualContainer = document.querySelector('.js-visual-container');
    this.propsContainer = document.querySelector('.js-props-container');
    this.props = this.divScroll = this.propsContainer.querySelector('.js-props');
    this.divScrollInner = this.divScroll.querySelector('.js-scrollable-content-inner');
    this.propsEl = this.divScrollInner.querySelector('.js-props-part');

    this.propsProps = this.propsEl.querySelector('.js-props-props');

    this.ui = document.querySelector('.js-ui');

    this.downloadLink = document.querySelector('.js-download');

    this.btnCustomize = this.ui.querySelector('.js-customize');
    this.btnFinish = this.ui.querySelector('.js-finish');
    this.btnReset = this.ui.querySelector('.js-reset');
    this.btnRandom = this.ui.querySelector('.js-random');

    
    this.screenshotPolaroid = this.ui.querySelector('.js-screenshot');
    this.imgScreen = this.screenshotPolaroid.querySelector('.js-img');

    this.tooltip = this.ui.querySelector('.js-tooltip');
  }

  events() { 
    this.btnCustomize.addEventListener('click', this.openCustomize)
    this.btnReset.addEventListener('click', this.resetColor)
    this.btnRandom.addEventListener('click', this.randomizeColor)
    this.btnFinish.addEventListener('click', this.askScreenshot)

    this.tooltip.addEventListener('click', this.showTooltip)

    this.parent.emitter.on('send-screenshot', this.hasScreenshot)
  }

  init() {
    return new Promise((resolve, reject) => {
      const promises = [this.initUI()]


      Promise.all(promises)
      .then(() => {
        resolve();
      })
    });
  }

  initUI() {
    return new Promise((resolve, reject) => {
      const promises = [this.initProps()]


      Promise.all(promises)
      .then(() => {
        this.initScroll()
      })
      .then(() => {
        this.initEvents()
      })
      .then(() => {
        resolve();
      })
    });
  }

  initProps() {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < gnomeData.parts.length; i++) {
        this.gnomeParts.push(gnomeData.parts[i])
        const part = gnomeData.parts[i];
        const partDom = this.propsEl.cloneNode(true);
        partDom.classList.add('js-props-part');
        const titleDom = partDom.querySelector('.js-props-title');
        titleDom.innerText = part.name;
        
        this.createColorPicker(partDom, part.baseColor, part.name, part.nameEnglish);
        
        this.divScrollInner.appendChild(partDom);
      }
      this.propsEl.remove();

      resolve()
    });
  }

  initEvents() {
    this.inputColors.forEach((element) => {
      element.addEventListener('input', this.updateColor)
    });
  }

  openCustomize() {

    this.propsOpened = !this.propsOpened

    this.propsContainer.classList.toggle('is-open')
    this.visualContainer.classList.toggle('is-translated')

    this.btnCustomize.classList.toggle('open-personalize')
  }


  valueToHex(c) {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }  

  rgbToHex(r, g, b) {
    return "#" + this.valueToHex(r) + this.valueToHex(g) + this.valueToHex(b);
  }

  createColorPicker(partDom, baseColor, name, nameEnglish) {
    const colorItem = partDom.querySelector('.js-props-color-elem');


    const inputColor = document.createElement('input');
    inputColor.setAttribute('type', 'color');
    inputColor.setAttribute('defaultValue', baseColor);
    inputColor.setAttribute('value', baseColor);
    inputColor.setAttribute('name', nameEnglish);

    colorItem.style.setProperty('--colorInput', baseColor);

    this.inputColors.push(inputColor);

    colorItem.appendChild(inputColor);
  }

  updateColor(event) {
    this.parent.emitter.emit('updateColor', {color: event.target.value, name: event.target.name})

    const lSName = 'color-' + event.target.name
    localStorage.setItem(lSName, event.target.value);
  }

  setColor() {
    const baseGardenDwarf = []

    if(localStorage.getItem("color-beard") !== null) {
      for(let i = 0; i < this.inputColors.length; i++) {
        const lSName = 'color-' + this.inputColors[i].name
        const lSValue = localStorage.getItem(lSName)

        this.inputColors[i].value = lSValue;
        baseGardenDwarf.push({color: lSValue, name: this.inputColors[i].name})
        localStorage.setItem(lSName, lSValue);
      }
    } else {
      this.setInitialColor(baseGardenDwarf);
    }

    this.parent.emitter.emit('setColor', baseGardenDwarf)
  }

  setInitialColor(array) {
    for(let i = 0; i < this.inputColors.length; i++) {
      array.push({color: this.inputColors[i].defaultValue, name: this.inputColors[i].name})
      const lSName = 'color-' + this.inputColors[i].name

      localStorage.setItem(lSName, this.inputColors[i].defaultValue);
    }
  }

  resetColor() {
    localStorage.clear();

    this.setColor();
  }

  randomizeColor() {
    const randomGardenDwarf = []
    for(let i = 0; i < this.inputColors.length; i++) {

      let value = null;

      if(this.inputColors[i].name === 'skin') {
        const randomNb = randomIntFromInterval(0, this.skinsColor.length - 1)

        value = this.skinsColor[randomNb]
      } else {
        value = this.randomHexColorCode()
      }

      this.inputColors[i].value = value;
      const lSName = 'color-' + this.inputColors[i].name
      localStorage.setItem(lSName, value);
      randomGardenDwarf.push({color: value, name: this.inputColors[i].name})
    }

    this.parent.emitter.emit('setColor', randomGardenDwarf)
  }

  randomHexColorCode() {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  };

  askScreenshot() {
    this.parent.emitter.emit('askScreenshot')
  }

  hasScreenshot(element) {
    this.screenshotPolaroid.classList.add('is-visible')

    this.downloadLink.href = element.url;
    this.downloadLink.download = 'mon-nain-de-jardin.png';

    this.imgScreen.src = element.url;
    this.imageUrl = element.url;
    this.imageBlob = element.blob;
  }


  showTooltip() {
    if(this.parent.isMobile) {
      this.tooltip.classList.toggle('is-clicked')
    }
  }

  initScroll() {
    this.oScroll = new Lenis({
      wrapper: this.divScroll,
      content: this.divScrollInner,
      eventsTarget: this.divScrollInner,
      smoothTouch: true,
      syncTouch: true,
      duration: 1.6,
    })
  }

  resize(size){  
    this.size = {
      x: size.x,
      y: size.y
    }

    if(!this.parent.isMobile) {
      this.tooltip.classList.toggle('is-desktop')
    }
    this.ratioScreen = this.size.x / this.size.y
  }

  debugMode(pane) {
    const page = pane.addFolder({
      title: 'Home',
    });
  }

  update({ time, deltaTime }) {

    if(this.oScroll) {
      
      const lenisTime = time * 1000
      this.oScroll.raf(lenisTime)
    }
    
  }
}
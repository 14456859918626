import '../sass/index.scss';
import { Pane } from 'tweakpane';
import * as EssentialPlugin from '@tweakpane/plugin-essentials';
import WebGLManager from './manager/WebGLManager.js';
import PageGL from './engine/PageGL.js';
import Home from './page/Home.js';
import { spring } from './utils/spring.js';
import Emitter from 'tiny-emitter'

import { wait } from './utils/utilsFunc.js';


const pane = new Pane();
pane.registerPlugin(EssentialPlugin)

export default class App {
  constructor(options){
    this.container = options.dom;


    this.startingTime = 0;
    this.lastTime = 0;
    this.timeLoop = 0;
    this.timeLoopMs = 0
    this.deltaTimeLoop = 0;

    this.animateIntroContent = false;

    this.global = null;

    this.debugBoundsGUI = null

    this.timePaused = true;
    this.isFinished = false;

    this.isLoaded = false;

    this.introHidden = false;

    this.isFirefox = navigator.userAgent.toLowerCase().includes('firefox');

    this.emitter = new Emitter()

    this.progressIntro = 0;
    this.easedProgressIntro = 0;
    this.scaleIntroSpringFirst = spring({ initial: 0, step: 10, tension: 0.15, friction: 0.15 });
    this.scaleIntroSpringSecond = spring({ initial: 0, step: 10, tension: 0.15, friction: 0.15 });
    this.scaleIntroSpringThird = spring({ initial: 0, step: 10, tension: 0.15, friction: 0.15 });
    this.scaleIntroSpringGnome = spring({ initial: 0, step: 10, tension: 0.15, friction: 0.15 });

    this.scaleIntroSpringContent = spring({ initial: 0, step: 10, tension: 0.15, friction: 0.15 });

    this.clickButtonIntro = spring({ initial: -15, step: 10, tension: 0.15, friction: 0.15 });

    this.size = {
      x: window.innerWidth,
      y: window.innerHeight
    }

    this.ratioScreen = this.size.x / this.size.y
    this.devicePixelRatio = window.devicePixelRatio;

    this.WebGLManager = new WebGLManager(this.container, this.size, pane)
    this.pageGL = new PageGL({parent: this, size: this.size})
    this.pageUI = new Home({parent: this, size: this.size})


    this.debuggerBool = true

    this.progressCurrent = 0;
    this.newProgress = 0

    this.showedGui = false;

    this.fpsGraph = pane.addBlade({
      view: 'fpsgraph', label: 'FPS', lineCount: 2
    })

    this.params = {
      global: {
      },
    }

    document.body.style.setProperty('--inner-height', window.innerHeight + 'px');

    this.isMobile = window.matchMedia('(pointer: coarse)').matches || window.innerWidth < 1280;
    const promises = []

    Promise.all(promises)
    .then(() => {
      this.paramsGlobal = this.params.global;

      this.bindMethods();
      this.getElems();
      this.init(options)
      this.events()


      this.render();
      this.debug()
      })

    
  }

  getElems() {
    this.GUITweapane = document.querySelector('.tp-dfwv');

    this.mainEl = document.querySelector('.js-main');

    this.canvasContainer = document.querySelector('.js-canvas-container');

    this.squareContainer = document.querySelector('.js-square-container');


    this.intro = document.querySelector('.js-intro');

    this.introLoader = this.intro.querySelector('.js-intro-loader');

    this.introContent = this.intro.querySelector('.js-intro-content');

    this.introFirstText = this.introContent.querySelector('.js-intro-first-text');
    this.introSecondText = this.introContent.querySelector('.js-intro-second-text');
    this.introThirdText = this.introContent.querySelector('.js-intro-third-text');
    this.introGnome = this.introContent.querySelector('.js-intro-gnome-container');

    this.introContentBtn = this.introContent.querySelector('.js-intro-content-btn');
    this.introBtn = this.introContent.querySelector('.js-intro-start');
    this.introBtnWrapper = this.introBtn.querySelector('.cta-wrapper');

    this.introImages = this.intro.querySelectorAll('.js-intro-img');
  }

  bindMethods() {
    this.render = this.render.bind(this)
    this.resize = this.resize.bind(this)
    this.keyEvent = this.keyEvent.bind(this)
    this.displayGui = this.displayGui.bind(this)
    this.cb = this.cb.bind(this)
    this.hideIntro = this.hideIntro.bind(this)
    this.animateIntro = this.animateIntro.bind(this)
  }

  events() {
    window.addEventListener('resize', this.resize)
    window.addEventListener('orientationchange', this.resize)

    this.observer = new ResizeObserver(this.cb)

    this.observer.observe(this.squareContainer)

    document.addEventListener('keydown', this.keyEvent)

    this.introBtn.addEventListener('click', this.animateIntro)

    this.emitter.on('introEnd', this.hideIntro)
  }

  keyEvent(e) {
    if(e.key === 't') {
      this.debuggerBool = !this.debuggerBool

      this.changeGUI(this.debuggerBool)
    }
  }

  displayGui() {
    this.changeGUI(this.showedGui)

    this.showedGui = !this.showedGui
  }

  changeGUI(bool) {
    this.GUITweapane.style.display = bool ? 'block' : 'none';
  }

  init(options) {
    const promises = []

    this.intro.classList.add('isInit')

    this.changeGUI(!this.isMobile)

    promises.push(this.pageGL.init(), this.pageGL.initAssets(), this.pageUI.init())

    Promise.all(promises)
    .then(() => {
      this.pageUI.setColor()
    })
    .then(async () => {
      await wait(250);
      this.isLoaded = true;
      this.mainEl.classList.remove('isHidden')
      this.mainEl.classList.add('isLoaded')
      this.introContent.classList.add('isLoaded')
      this.introLoader.classList.add('isLoaded')

      this.introImages.forEach((img) => { img.classList.add('isLoaded') })

      this.scaleIntroSpringFirst.setTarget(1);
      this.scaleIntroSpringSecond.setTarget(1);
      this.scaleIntroSpringThird.setTarget(1);
      this.scaleIntroSpringGnome.setTarget(1);
      this.scaleIntroSpringContent.setTarget(1);
      this.resize();
    })

    .then(async() => {
      await wait(600);
        this.introLoader.classList.add('isHidden')
        this.scaleIntroSpringFirst.setValue(1);
        this.introImages[0].classList.add('isVisible')
      await wait(200);
          this.scaleIntroSpringSecond.setValue(1);
          this.introImages[1].classList.add('isVisible')
      await wait(200);
        this.scaleIntroSpringThird.setValue(1);
        this.introImages[2].classList.add('isVisible')
      await wait(200);
          this.scaleIntroSpringGnome.setValue(1);
          this.scaleIntroSpringContent.setValue(1);
          this.introImages[3].classList.add('isVisible')
          this.introImages[4].classList.add('isVisible')

      await wait(800);
    })
  }

  animateIntro() {
    this.clickButtonIntro.setValue(0)

    this.intro.classList.add('isHidden')

    this.emitter.emit('animateIntroModel')
  }

  hideIntro() {
    this.introHidden = true;

    this.intro.remove()
  }


  resize(){
    this.devicePixelRatio = window.devicePixelRatio;  

    this.isMobile = window.matchMedia('(pointer: coarse)').matches || window.innerWidth < 1280;
    document.body.style.setProperty('--inner-height', window.innerHeight + 'px');

    this.WebGLManager.resize();

    this.pageGL.resize(this.size, this.devicePixelRatio)
    this.pageUI.resize(this.size, this.devicePixelRatio)
  }

  cb(entries) {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[ i ];
      const w = entry.contentRect.width;
      const h = entry.contentRect.height;

      const viewportRatio = window.innerWidth / window.innerHeight;

      const aspectRatio =
        viewportRatio <= 1 ?
          w < h ? w : h
          :
          w > h ? h : w;

      if(this.isMobile || window.innerWidth < 1280) {

        this.canvasContainer.style.width = aspectRatio + 'px';
        this.canvasContainer.style.height = aspectRatio + 'px';

        document.body.style.setProperty('--topOffset', aspectRatio + 'px');
        document.body.style.setProperty('--heightProps', window.innerHeight - aspectRatio + 'px');

        this.size.x = this.size.y = aspectRatio;
      } else {

        // this.size.x = w;
        // this.size.y = h;
        this.canvasContainer.style.width = '100%';
        this.canvasContainer.style.height = '100%';
        this.size.x = this.size.y = aspectRatio;
      }

      this.resize();
    }
  }

  render(currentTime){
    // TODO CHECCK https://www.testufo.com/
    if(!this.startingTime) this.startingTime = currentTime;
    if(!this.lastTime) this.lastTime = currentTime;
    this.timeLoopMs = (currentTime-this.startingTime);
    this.timeLoop = this.timeLoopMs / 1000;
    this.deltaTimeLoop = (currentTime-this.lastTime) / 1000;
    this.lastTime = currentTime;

    const time = this.timeLoop;
    const deltaTime = this.deltaTimeLoop;

    window.requestAnimationFrame(this.render)
    this.fpsGraph.begin()


    if(!this.introHidden) {
      this.scaleIntroSpringFirst.update(deltaTime * 1000)
      this.scaleIntroSpringSecond.update(deltaTime * 1000)
      this.scaleIntroSpringThird.update(deltaTime * 1000)
      this.scaleIntroSpringGnome.update(deltaTime * 1000)
      this.scaleIntroSpringContent.update(deltaTime * 1000)

      this.clickButtonIntro.update(deltaTime * 1000)
  
  
      this.introFirstText.style.transform  =  `scale(${this.scaleIntroSpringFirst.value.toFixed(3)})`;
      this.introSecondText.style.transform  = `scale(${this.scaleIntroSpringSecond.value.toFixed(3)})`;
      this.introThirdText.style.transform  = `scale(${this.scaleIntroSpringThird.value.toFixed(3)})`;
      this.introGnome.style.transform  = `scale(${this.scaleIntroSpringGnome.value.toFixed(3)})`;
      this.introContentBtn.style.transform  = `scale(${this.scaleIntroSpringContent.value.toFixed(3)})`;

      this.introBtn.style.setProperty('--translateBtn', this.clickButtonIntro.value.toFixed(3) + '%');
    }



    if(!this.isLoaded) return;
    if(this.WebGLManager) {
      this.WebGLManager.render({
        time,
        deltaTime
      })
    }
    this.pageGL.update({
      time,
      deltaTime
    })
    this.pageUI.update({
      time,
      deltaTime
    })
    this.fpsGraph.end()    
    
  }

  debug() {
    this.global = pane.addFolder({
      title: 'Global',
      expanded: false
    });

    WebGLManager.instance.debugMode(pane)

    this.pageGL.debugMode(pane)
  }

}

new App({
  dom: document.querySelector('.app')
});
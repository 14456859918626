import {
  Mesh,
  BoxGeometry,
  Vector2,
  MeshNormalMaterial,
  AmbientLight,
  MeshBasicMaterial,
  DoubleSide,
  MeshStandardMaterial,
  MathUtils,
} from 'three'
import gsap from 'gsap';
import WebGLManager from '../manager/WebGLManager'
import nain from '../../model/nain_v2.glb'
export default class PageGL {
  constructor({ parent, size }){

    this.parent = parent;
    this.size = {
      x: size.x,
      y: size.y
    }    
    this.scene = null;
    this.loaded = false

    this.WebGLManager = WebGLManager;
    
    this.texturesToLoad = []
    this.modelsToLoad = [nain]
    this.hdrsToLoad = []
    this.KTX2LoaderToLoad = []
    this.ratioScreen = this.size.x / this.size.y

    this.db = new Vector2(window.innerWidth, window.innerHeight)

    this.params = {
      enableRotation: true
    }

    this.bindMethods()

    this.events()
  }

  bindMethods() {
    this.update = this.update.bind(this)
    this.resize = this.resize.bind(this)
    this.updateColor = this.updateColor.bind(this)
    this.setColor = this.setColor.bind(this)
    this.askScreenshot = this.askScreenshot.bind(this)
    this.animateModel = this.animateModel.bind(this)
  }

  events() {
    this.parent.emitter.on('updateColor', this.updateColor)

    this.parent.emitter.on('setColor', this.setColor)

    this.parent.emitter.on('askScreenshot', this.askScreenshot)

    this.parent.emitter.on('animateIntroModel', this.animateModel)
  }

  init() {
    return new Promise((resolve, reject) => {
      const promises = []


      Promise.all(promises)
      .then(() => {
        resolve();
      })
    });
  }

  initAssets() {
    return new Promise((resolve, reject) => {
      const promisesAssets = []

      promisesAssets.push(WebGLManager.prepare(this))

      Promise.all(promisesAssets)
      .then(() => {
        this.WebGLManager.isReady(true)
      })
      .then(() => {
        resolve();
      })
    });
  }

  componentToHex(c) {
    const colorEl = Number(c.toFixed());
    const hex = colorEl.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  } 

  rgbToHex(r, g, b) {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  ready({textures, models, hdr, KTX2}) {
    this.WebGLManager.enabledControl();
    const scene = models[0].scenes[0]

    if (scene) {
      scene.traverse((child) => {
        if (child instanceof Mesh) {
            const color = this.rgbToHex(child.material.color.r * 255, child.material.color.g * 255, child.material.color.b * 255)

            child.material = null;
            child.material = new MeshStandardMaterial({ color, side: DoubleSide, roughness: 0.35, metalness: 0.2 });

            child.material.needsUpdate = true;

            this[child.name] = child
          }
      });
    }

    scene.scale.set(0,0,0)

    this.scene = scene

    WebGLManager.addToScene(scene)
  }

  animateModel() {
    this.tl = gsap.timeline({
      onComplete: () => {
        this.parent.emitter.emit('introEnd')
      }
    })

    

    this.tl.fromTo(this.scene.scale, 
      { x: 0, y: 0, z: 0 },
      { x: 1, y: 1, z: 1, duration: 1, delay: 0.25, ease: 'power2.inOut'},
      'start')

      this.tl.fromTo(this.scene.rotation, 
        { y: 0, z: 0 },
        { y: MathUtils.degToRad(360), z: 0, duration: 1, delay: 0.25, ease: 'power2.inOut'}, 'start')
  }

  updateColor({ name, color }) {
    const colorFormat = this.hexToRgb(color)

    this[name].material.color.r = colorFormat.r / 255
    this[name].material.color.g = colorFormat.g / 255
    this[name].material.color.b = colorFormat.b / 255
  }

  setColor(baseColors) {

    for(let i = 0; i < baseColors.length; i++) {
      const colorFormat = this.hexToRgb(baseColors[i].color)

      this[baseColors[i].name].material.color.r = colorFormat.r / 255
      this[baseColors[i].name].material.color.g = colorFormat.g / 255
      this[baseColors[i].name].material.color.b = colorFormat.b / 255
    }

  }

  askScreenshot() {

    this.WebGLManager.render();

      const promiseCapture = new Promise((resolve, reject) => {
        this.WebGLManager.instance.renderer.domElement.toBlob((blob) => {
          this.url = URL.createObjectURL(blob);

          this.parent.emitter.emit('send-screenshot', {url: this.url, blob})

          resolve();
        }, 'image/png', 1.0);
      })
  }

  resize(size){  
    this.size = {
      x: size.x,
      y: size.y
    }
    this.ratioScreen = this.size.x / this.size.y
  }

  debugMode(pane) {
    const three = pane.addFolder({
      title: 'Three',
    });

    three.addBinding(this.params, 'enableRotation', {
    }).on('change', ({ value }) => {
      this.params.enableRotation = value
    })
  }

  update({ time, deltaTime }) {
    if(!this.params.enableRotation || !this.cube) return
    this.cube.rotation.x += deltaTime * 0.5;
    this.cube.rotation.y += deltaTime * 0.5;
  }
}